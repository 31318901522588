<template>
  <div>
     <div class="goods_list_item_slelect">
          <a-select default-value="选择类目" style="width: 120px;height:25px" @change="handleChange" :loading="loading">
            <a-select-option v-for="(item,i) in selectArray" :value="item" :key="i">
              {{item}}
            </a-select-option>
       </a-select>
    </div>
  </div>
</template>
<script>
export default {
  name: "inputSelect",
  data() {
    return {
      loading: false
    };
  },
  props: {
    selectArray: {
      type: Array,
      required: true
    }
  },
  methods: {
    // 一级选择
    handleChange(value) {
      this.loading = true;
      console.log(`selected ${value}`);
      this.loading = false;
    }
  }
};
</script>
<style lang="less" scoped>
.goods_list_item_slelect {
  /deep/.ant-select-focused {
    border-color: #ecac91;
  }
  .ant-select-open .ant-select-selection {
    border-color: #ecac91;
  }
  .ant-select-open:hover {
    border-color: #ecac91;
  }
  /deep/.ant-select-selection:hover {
    border-color: #ecac91;
  }
  .ant-select-open /deep/.ant-select-selection:hover {
    border-color: #ecac91;
  }
}
</style>
